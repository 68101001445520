import { ERROR_APPEAR, ERROR_CLEAR } from '../constants';

export function showError(title, message, exceptionMessage) {
  return dispatch => {
    dispatch({
      type: ERROR_APPEAR,
      payload: {
        showError: true,
        title,
        message,
        exceptionMessage,
      },
    });
  };
}

export function clearError() {
  return dispatch => {
    dispatch({
      type: ERROR_CLEAR,
      payload: {
        showError: false,
      },
    });
  };
}

export default { showError, clearError };
