import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import { FormattedMessage } from 'react-intl';
import { fieldPropTypes as reduxFormFieldPropTypes } from 'redux-form';

class TextField extends React.Component {
  static propTypes = {
    ...reduxFormFieldPropTypes,

    disabled: PropTypes.bool,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    hint: null,
    icon: null,
    placeholder: null,
    maxLength: null,
    required: false,
    type: 'text',
  };

  constructor(props) {
    super(props);
    this.renderErrorMessage = this.renderErrorMessage.bind(this);
  }

  inputProps() {
    const { icon } = this.props;
    if (icon) {
      let startAdornment;
      if (typeof icon === 'string') {
        // Font icon
        startAdornment = (
          <InputAdornment position="start">
            <Icon>{icon}</Icon>
          </InputAdornment>
        );
      } else {
        // SVG icon
        startAdornment = (
          <InputAdornment position="start">{icon}</InputAdornment>
        );
      }

      return { startAdornment };
    }
    return {};
  }

  renderErrorMessage() {
    const {
      meta: { error, touched },
    } = this.props;
    if (touched && error) {
      return <FormattedMessage {...error} />;
    }
    return undefined;
  }

  render() {
    let { label } = this.props;
    const { placeholder } = this.props;
    if (this.props.required) {
      label = <span>{label} *</span>;
    }
    return (
      <MuiTextField
        {...this.props.input}
        InputProps={this.inputProps()}
        label={label}
        placeholder={placeholder}
        maxLength={this.props.maxLength}
        type={this.props.type}
        error={!!this.renderErrorMessage()}
        helperText={this.renderErrorMessage()}
        disabled={this.props.disabled}
      />
    );
  }
}

export default TextField;
