import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ok: {
    id: 'common.ok',
    defaultMessage: 'OK',
    description: 'OK',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel',
  },
  dismiss: {
    id: 'common.dismiss',
    defaultMessage: 'Dismiss',
    description: 'Dismiss',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save',
    description: 'Save button text',
  },
  load: {
    id: 'common.load',
    defaultMessage: 'Load',
    description: 'Load button text',
  },
  details: {
    id: 'common.details',
    defaultMessage: 'Details',
    description: 'Details',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Add',
    description: 'Add',
  },
  remove: {
    id: 'common.remove',
    defaultMessage: 'Remove',
    description: 'Remove',
  },
  clear: {
    id: 'common.clear',
    defaultMessage: 'Clear',
    description: 'Clear',
  },
  return: {
    id: 'common.return',
    defaultMessage: 'Return',
    description: 'Return',
  },
  updatedAt: {
    id: 'common.updatedAt',
    defaultMessage: 'Updated at',
    description: 'Updated at',
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Edit',
    description: 'Edit',
  },
  search: {
    id: 'common.search',
    defaultMessage: 'Search',
    description: 'Search',
  },
  startDate: {
    id: 'common.startDate',
    defaultMessage: 'Start date',
    description: 'Start date',
  },
  endDate: {
    id: 'common.endDate',
    defaultMessage: 'End date',
    description: 'End date',
  },
  exportButton: {
    id: 'common.exportButton',
    defaultMessage: 'Export',
    description: 'Export button',
  },
  commaSeparator: {
    id: 'common.commaSeparator',
    defaultMessage: ', ',
    description: 'Comma separator',
  },
  none: {
    id: 'common.none',
    defaultMessage: 'None',
    description: 'None',
  },
  create: {
    id: 'common.create',
    defaultMessage: 'Create',
    description: 'Create',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
    description: 'Close',
  },
  reset: {
    id: 'common.reset',
    defaultMessage: 'Reset',
    description: 'Reset',
  },
  status: {
    id: 'common.status',
    defaultMessage: 'Status',
    description: 'Status',
  },
  createdAt: {
    id: 'common.createdAt',
    defaultMessage: 'Created At',
    description: 'Created at',
  },
  error: {
    id: 'common.error',
    defaultMessage: 'Error',
    description: 'Error',
  },
  confirm: {
    id: 'common.confirm',
    defaultMessage: 'Confirm',
    description: 'Confirm',
  },
  description: {
    id: 'common.description',
    defaultMessage: 'Description',
    description: 'Description',
  },
  active: {
    id: 'common.active',
    defaultMessage: 'Active',
    description: 'Active',
  },
  all: {
    id: 'common.all',
    defaultMessage: 'All',
    description: 'All',
  },
  date: {
    id: 'common.date',
    defaultMessage: 'Date',
    description: 'Date',
  },
  fileName: {
    id: 'common.fileName',
    defaultMessage: 'File name',
    description: 'File name',
  },
  formErrorTitle: {
    id: 'common.formErrorTitle',
    defaultMessage: 'Error Occurred',
    description: 'Form error title',
  },
  formErrorDescription: {
    id: 'common.formErrorDescription',
    defaultMessage: 'Please check the error details and try again',
    description: 'Form error description',
  },
});

export default messages;
