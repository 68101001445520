import { defineMessages } from 'react-intl';
import { isValidPostcode } from '../../AddressForm/AddressFormatter';

export const ValidatorMessages = defineMessages({
  required: {
    id: 'intl.reduxForm.validators.required',
    defaultMessage: 'Required',
    description: 'indicate field is required',
  },
  requiredLength: {
    id: 'intl.reduxForm.validators.requiredLength',
    defaultMessage: 'Must be exactly {length} characters',
    description: 'indicate field requires some length',
  },
  maxLength: {
    id: 'intl.reduxForm.validators.maxLength',
    defaultMessage: 'Max {length} characters',
    description: 'indicate field has a max length',
  },
  minLength: {
    id: 'intl.reduxForm.validators.minLength',
    defaultMessage: 'Min {length} characters',
    description: 'indicate field has a min length',
  },
  alreadyExists: {
    id: 'intl.reduxForm.validators.alreadyExists',
    defaultMessage: 'Already Exists',
    description: 'indicate field should be unique and value is already taken',
  },
  notFound: {
    id: 'intl.reduxForm.validators.notFound',
    defaultMessage: 'Not Found',
    description: 'indicate id is unknown',
  },
  unexpectedError: {
    id: 'intl.reduxForm.validators.error',
    defaultMessage: 'Unexpected Error',
    description: 'When unexpected error happens',
  },
  noWhitespace: {
    id: 'intl.reduxForm.validators.noWhitespace',
    defaultMessage: 'Whitespace not allowed',
    description: 'Indicate presence of unallowed whitespace',
  },
  number: {
    id: 'intl.reduxForm.validators.number',
    defaultMessage: 'Must be a number',
    description: 'Indicate a string has non-number characters',
  },
  range: {
    id: 'intl.reduxForm.validators.range',
    defaultMessage: 'Must be in range {min} to {max}',
    description: 'Indicate a number is out of range',
  },
  ascii: {
    id: 'intl.reduxForm.validators.ascii',
    defaultMessage: 'Only ASCII characters allowed',
    description:
      'Indicate a field contains other characters than ASCII characters',
  },
  maxNrOfRows: {
    id: 'intl.validations.maxNrOfRows',
    defaultMessage: 'Cannot have more than {n} rows',
    description: 'Error message for a field than n rows',
  },
  maxLengthPerRow: {
    id: 'intl.validations.maxLengthPerRow',
    defaultMessage: 'Each row can have at most {n} characters',
    description: 'Indicate max length per row',
  },
  time: {
    id: 'intl.validations.time',
    defaultMessage: 'Not a valid time',
    description: 'Indicate incorrect time input',
  },
  startTimeLargerThanEndTime: {
    id: 'intl.validations.startTimeLargerThanEndTime',
    defaultMessage: 'The end time must come after the start time',
    description: 'Indicate start time is after end time',
  },
  commaSeparatedNumbers: {
    id: 'intl.validations.commaSeparatedNumbers',
    defaultMessage: 'Only numbers and commas allowed',
    description:
      'Indicate field contains other characters than numbers and commas',
  },
  email: {
    id: 'intl.reduxForm.validators.email',
    defaultMessage: 'Must be an email address',
    description: 'Indicate a string is not an email address',
  },
  validCharacters: {
    id: 'intl.validations.validCharacters',
    defaultMessage: 'Contains invalid characters.',
    description: 'Error message when a field contains invalid characters',
  },
  telephone: {
    id: 'intl.validations.telephone',
    defaultMessage: 'Only single byte numbers, () and "-" allowed',
    description: 'Error message when a field contains non-telephone characters',
  },
  integerOnly: {
    id: 'intl.validations.integerOnly',
    defaultMessage: 'Only single byte numbers allowed',
    description: 'Error message when a field contains non-telephone characters',
  },
  imageUrl: {
    id: 'intl.validations.imageUrl',
    defaultMessage: 'Not a valid image url',
    description: 'Error message when a field contains a non valid image url',
  },
  janCode: {
    id: 'intl.validations.janCode',
    defaultMessage:
      'Not a valid JAN code. Please enter a correct JAN code e.g. 4910101010101',
    description: 'Error message when a field contains a non valid jan code',
  },
  positiveNumber: {
    id: 'intl.validations.positiveNumber',
    defaultMessage: 'Only numbers 1 or greater allowed',
    description: 'Error message when a field contains a non valid jan code',
  },
  machineNo: {
    id: 'intl.validations.machineNo',
    defaultMessage:
      'Not a valid machine number. Please enter a correct machine number e.g. ABC12345DEF',
    description: 'Error message when a field contains a non valid machine no',
  },
  postcode: {
    id: 'intl.validations.postcode',
    defaultMessage:
      'Invalid postcode. Please enter a valid postcode e.g. 1500001',
    description: 'Error message when the postcode field is invalid',
  },
});

export const required = value => {
  // All booleans are acceptable
  if (value === false || value === true) {
    return undefined;
  }

  if (value && value.constructor === Array && value.length === 0) {
    return ValidatorMessages.required;
  }

  // Special case of number which is 0 (which should be allowed)
  if (typeof value === 'number' && value === 0) {
    return undefined;
  }

  const isString = typeof value === 'string';
  const trimedValue = isString ? value.trim() : value;
  if (trimedValue) {
    return undefined;
  }
  return ValidatorMessages.required;
};

export const requiredLength = length => value => {
  let result;
  if (!(value && value.length === length)) {
    result = { ...ValidatorMessages.requiredLength };
    result.values = { length };
  }
  return result;
};

export const maxLength = length => value => {
  let result;
  if (value && value.length > length) {
    result = { ...ValidatorMessages.maxLength };
    result.values = { length };
  }
  return result;
};

export const minLength = length => value => {
  let result;
  const valueLength = (value && value.length) || 0;
  if (valueLength < length) {
    result = { ...ValidatorMessages.minLength };
    result.values = { length };
  }
  return result;
};

export const maxNrOfRows = n => value => {
  let result;
  if (value) {
    const rows = value.split('\n');
    if (rows.length > n) {
      result = { ...ValidatorMessages.maxNrOfRows };
      result.values = { n };
    }
  }
  return result;
};

export const maxLengthPerRow = length => value => {
  let result;
  if (value) {
    const rows = value.split('\n');
    if (rows.find(row => row.length > length)) {
      // Need to shallow clone this to have multiple messages with different n
      result = { ...ValidatorMessages.maxLengthPerRow };
      result.values = { n: length };
    }
  }
  return result;
};

export const noWhitespace = value =>
  value && /.*\s.*/.test(value) ? ValidatorMessages.noWhitespace : undefined;

export const number = value =>
  value && Number.isNaN(Number(value)) ? ValidatorMessages.number : undefined;

export const integerOnly = value =>
  /^[0-9]*$/.test(value) ? undefined : ValidatorMessages.integerOnly;

export const range = (min, max) => value => {
  if (value === undefined || value === null || value === '') {
    return undefined;
  }
  if (Number.isNaN(Number(value))) {
    return ValidatorMessages.number;
  }
  let result;
  if (value < min || value > max) {
    result = { ...ValidatorMessages.range };
    result.values = { min: min.toString(), max: max.toString() };
  }
  return result;
};

export const ascii = value =>
  // eslint-disable-next-line no-control-regex
  !value || /^[\x00-\x7F]*$/.test(value) ? undefined : ValidatorMessages.ascii;

export const commaSeparatedNumbers = value =>
  !value || /^[\d,]*$/.test(value)
    ? undefined
    : ValidatorMessages.commaSeparatedNumbers;

export const email = value => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (value && !re.test(value) && ValidatorMessages.email) || undefined;
};

export const validCharacters = regex => value =>
  !value || regex.test(value) ? undefined : ValidatorMessages.validCharacters;

export const validateTelephone = value =>
  !value || /^[0-9()-]*$/.test(value) ? undefined : ValidatorMessages.telephone;

export const validateImageUrl = value =>
  !value || /^(http)(s)?(:\/\/)\S+$/.test(value)
    ? undefined
    : ValidatorMessages.imageUrl;

export const validateJanCode = value =>
  !value ||
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    value,
  ) || // Allow uuid
  /^([0-9]{13}|[0-9]{8})$/.test(value)
    ? undefined
    : ValidatorMessages.janCode;

export const validateMachineNo = value =>
  value && /^[0-9A-Z-]+$/.test(value) ? undefined : ValidatorMessages.machineNo;

export const positiveNumber = value =>
  parseInt(value, 10) > 0 ? undefined : ValidatorMessages.positiveNumber;

// TODO: Updated messages for validators below this line

export const validatePostcode = value =>
  isValidPostcode(value) ? undefined : ValidatorMessages.postcode;
