import { first, last } from 'lodash';
import postalCodeResolver from 'japan-postal-code';

const postcodeRegex = /^\d{7}$/;
export const isValidPostcode = postcode => postcodeRegex.test(postcode);

const prefectures = [
  /* eslint-disable prettier/prettier */
  '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
  '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
  '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県',
  '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県',
  '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県',
  '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県',
  '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県',
  /* eslint-enable prettier/prettier */
];
const prefectureRegex = new RegExp(`^(${prefectures.join('|')})`);

// Expected patern for the chome/street number
const dashes = '−|ー|-|－|―';
const digits = '[０-９0-9〇一二三四五六七八九十]+';
const pattern1 = '丁[目|目西|目東|目北|目南]*|番地'; // Expect 丁* or 番地 for the first part
const pattern2 = '番地|番|号|Ｆ'; // While these kanjis are for the latter parts
const line3Pattern = `${digits}(${pattern1})?([${dashes}]?${digits}[${pattern2}]*)*`;
const line3Regex = new RegExp(`^${line3Pattern}$`);

// Additional catch for cases where line 3 are only digits
const probableNotLine3 = line => {
  const regex = new RegExp(`^${digits}$`);
  return regex.test(line);
};

const isValidLine3 = line =>
  !line || (line3Regex.test(line) && !probableNotLine3(line));

export const postcodeToAddress = postcode =>
  new Promise(resolve => {
    if (!isValidPostcode(postcode)) {
      resolve(null);
      return;
    }

    postalCodeResolver.get(postcode, address => {
      if (address) {
        resolve({
          line1: address.prefecture,
          line2: [address.city, address.area, address.street].join(''),
        });
      } else {
        resolve(null);
      }
    });
  });

// https://www.yoheim.net/blog.php?q=20191101

const kanaMap = {
  /* eslint-disable prettier/prettier */
  'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
  'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
  'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
  'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
  'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
  'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
  'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
  'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
  'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
  'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
  'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
  'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
  'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
  'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
  'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
  'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
  'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
  'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
  '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
  /* eslint-enable prettier/prettier */
};
const hankana2ZenkanaRegex = new RegExp(
  `(${Object.keys(kanaMap).join('|')})`,
  'g',
);

const halfWidthToFullWidth = line =>
  (line || '')
    .replace(new RegExp(`(${dashes})`, 'g'), 'ー')
    // hankana2Zenkana
    .replace(hankana2ZenkanaRegex, match => kanaMap[match])
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜')
    // zenkaku2Hankaku
    .replace(/[A-Za-z0-9!-~]/g, match =>
      String.fromCharCode(match.charCodeAt(0) + 0xfee0),
    );

// Exported for unit testing
export const sanitizeAddress = item => {
  let { postcode } = item;
  const { line1, line2, line3, line4 } = item;

  // Converts full width to half width and keeps only digits
  postcode = (postcode || '').normalize('NFKC').replace(/[^0-9]/g, '');

  return {
    postcode,
    // Lines 1-3 gets the same treatment of half to full and removing
    // all the spaces. (Removing the spaces is important for the
    // location map)
    line1: halfWidthToFullWidth(line1).replace(/\s/g, ''),
    line2: halfWidthToFullWidth(line2).replace(/\s/g, ''),
    line3: halfWidthToFullWidth(line3).replace(/\s/g, ''),
    // Line 4 converts half to full width, trims all the leading/trailing
    // spaces, then condenses all the spaces to a full width space
    line4: halfWidthToFullWidth(line4)
      .trim()
      .replace(/\s+/g, '　'),
  };
};

export const autoCorrectAddress = item => {
  const sanitized = sanitizeAddress(item);
  // Start by sanitizing all the address fields
  let { line1, line2, line3, line4 } = sanitized;
  const postcode = sanitized.postcode || undefined;

  // First guess the line 3 and line 4
  // If line 3 is provided, chances are the rest of the address fields are.
  if (line3 && isValidLine3(line3)) {
    line1 = `${line1}${line2}` || undefined;
    line2 = undefined;
    line3 = line3 || undefined;
    line4 = line4 || undefined;
  } else {
    // Otherwise, the line3 might be appended on other fields, so we build the whole thing
    // and then guess based on the expected line 3 pattern
    const address = [line1, line2, line3].join('');
    const regexp = new RegExp(`${line3Pattern}`, 'g');
    const line3Match = address.match(regexp);
    // Filter out matches that are not entirely digits
    const matches =
      line3Match && line3Match.filter(match => !probableNotLine3(match));
    if (last(matches)) {
      let match = last(matches);

      // If the match starts with a Kanji number followed by a numeric digit,
      // then most likely the kanji digits does not belong to line 3
      const kanaFollowedByDigits = new RegExp(
        '^[〇一二三四五六七八九十]+[０-９0-9]+',
      );
      const index = match.search(/[０-９0-9]/);
      if (kanaFollowedByDigits.test(match) && index > 0) {
        match = match.substring(index);
      }

      // Split the address and put the left part to line 1
      const split = address.split(match);
      line1 = split[0] || undefined;
      line2 = undefined;

      // If both the parsed and original has some line 4, append the parsed to line 3
      if (line4 && split[1]) {
        line3 = `${match}${split[1]}`;
      } else {
        line3 = match;
        line4 = line4 || split[1] || undefined;
      }
    } else {
      // No pattern is detected for line 3, let's dump everything in line 1 (except line4)
      line1 = address || undefined;
      line2 = undefined;
      line3 = undefined;
      line4 = line4 || undefined;
    }
  }

  // By now, line 1 should have the original lines 1-2 or the full address without line 3
  // So we split it up by starting with the prefecture
  const prefMatch = prefectureRegex.exec(line1);
  if (first(prefMatch)) {
    const prefecture = first(prefMatch);
    line2 = line1.replace(prefecture, '');
    line1 = prefecture;
  }
  // Further work: as an `else` here, we can make this guess the prefecture based
  // on the postal code, however, this function needs to be `async` but on my tests
  // the processing sometimes hang on large data and i am not sure why.

  return {
    postcode,
    line1,
    line2,
    line3,
    line4,
  };
};
