import { connect } from 'react-redux';

class Authorizer {
  constructor(permissions) {
    this.permissions = permissions || [];
  }

  canSendBeaconCommands() {
    return this.permissions.includes('send_beacon_commands');
  }

  canSimulateBeaconEvents() {
    return this.permissions.includes('simulate_beacon_events_staging_only');
  }

  canRequestRemoteSalesCounters() {
    return this.permissions.includes('request_remote_sales_counters');
  }

  canManageUsers() {
    return this.permissions.includes('manage_users');
  }

  canCreateExports() {
    return this.permissions.includes('create_exports');
  }

  canShowRestrictions() {
    return this.permissions.includes('show_restrictions');
  }

  canReadAllOperators() {
    return this.permissions.includes('read_all_operators');
  }

  canUpdateMoneyCounters() {
    return this.permissions.includes('update_money_counters');
  }

  canShowRoutes() {
    return this.permissions.includes('show_routes');
  }

  canManageRoutes() {
    return this.permissions.includes('manage_routes');
  }

  canShowRouteWeeklySchedule() {
    return this.permissions.includes('show_route_weekly_schedule');
  }

  canShowRouteTodaysPlan() {
    return this.permissions.includes('show_route_todays_plan');
  }

  canShowRouteVehicleLoadingList() {
    return this.permissions.includes('show_route_vehicle_loading_list');
  }

  canShowRouteOperations() {
    return this.permissions.includes('show_route_operations');
  }

  canShowRouteCollectedMoney() {
    return this.permissions.includes('show_route_collected_money');
  }

  canShowRouteOperationsPickingList() {
    return this.permissions.includes('show_route_operations_picking_list');
  }

  canShowRouteOperationsColumnConfig() {
    return this.permissions.includes('show_route_operations_column_config');
  }

  canShowRouteOperationsButtonConfig() {
    return this.permissions.includes('show_route_operations_button_config');
  }

  canCreateVendingMachineModel() {
    return this.permissions.includes('create_vending_machine_model');
  }

  canManageRoles() {
    return this.permissions.includes('manage_roles');
  }

  canManagePermissions() {
    return this.permissions.includes('manage_permissions');
  }

  canManageOperatorProducts() {
    return this.permissions.includes('manage_operator_product');
  }

  canManageProductVariants() {
    return this.permissions.includes('manage_product_variants');
  }

  canManageUnmappedOperatorProducts() {
    return this.permissions.includes('manage_unmapped_operator_products');
  }

  canCreateResetRequests() {
    return this.permissions.includes('create_reset_request');
  }

  canShowFaultCodeList() {
    return this.permissions.includes('show_fault_code_list');
  }

  canReadOpBranches() {
    return this.permissions.includes('read_op_branches');
  }

  canCreateOpBranches() {
    return this.permissions.includes('create_op_branches');
  }

  canUpdateOpBranches() {
    return this.permissions.includes('update_op_branches');
  }

  canReadAllOpBranches() {
    return this.permissions.includes('read_all_op_branches');
  }

  canManageLocationOwnerFees() {
    return this.permissions.includes('manage_location_owner_fees');
  }

  canCreateLocationOwnerInvoiceExports() {
    return this.permissions.includes('create_location_owner_invoice_exports');
  }

  canCreateImports() {
    return this.permissions.includes('create_imports');
  }

  canCreateLocations() {
    return this.permissions.includes('create_locations');
  }

  canUpdateLocations() {
    return this.permissions.includes('update_locations');
  }

  canDeleteLocations() {
    return this.permissions.includes('delete_locations');
  }

  canCreateVendingMachines() {
    return this.permissions.includes('create_vending_machines');
  }

  canUpdateVendingMachines() {
    return this.permissions.includes('update_vending_machines');
  }

  canDeleteVendingMachines() {
    return this.permissions.includes('delete_vending_machines');
  }

  canShowSSFileConverter() {
    return this.permissions.includes('show_ss_file_converter');
  }

  canCreateProductInventoryExports = () =>
    this.permissions.includes('create_product_inventory_exports');

  canPrint = () => this.permissions.includes('print');
}

export const InjectAuthorizer = connect(state => {
  const currentUser = state.currentUser || {};
  const authorizer = new Authorizer(currentUser.permissionNames);
  return {
    authorizer,
  };
});

export default Authorizer;
