import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles as MuiWithStyles } from '@material-ui/core/styles';
import get from 'lodash.get';

// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
// eslint-disable-next-line css-modules/no-unused-class
import flexboxgrid from '../../../node_modules/flexboxgrid/dist/flexboxgrid.css';
import s from './Layout.css';
import history from '../../history';
import Sidebar from '../Sidebar';
import Link from '../Link';
import ErrorPopup from '../ErrorPopup';
import logoUrl from './logo_color.svg';

const muiLayoutStyles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    padding: '0 1.8rem',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
  },
});
class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    hideSidebar: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    currentUser: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    hideSidebar: false,
    currentUser: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      sideNavActive: false,
    };

    this.toggleSidebarActive = this.toggleSidebarActive.bind(this);
  }

  toggleSidebarActive() {
    this.setState(prevState => ({ sideNavActive: !prevState.sideNavActive }));
  }

  renderSidebar() {
    if (this.props.hideSidebar) {
      return null;
    }
    const currentPath = get(this.props, 'history.location.pathname', '');

    return (
      <Sidebar
        active={this.state.sideNavActive}
        onSidebarLinkClicked={this.toggleSidebarActive}
        history={this.props.history}
        currentUser={this.props.currentUser}
        currentPath={currentPath}
      />
    );
  }

  renderContents() {
    // Only render the contents if there's a user. This is mostly to avoid
    // strange behavior if navigating back. We cannot, however, automatically
    // navigate out of here if this happens as it could cause strange timing
    // issues since the redux timings are asynchronous.
    if (!this.props.hideSidebar && !this.props.currentUser) {
      return null;
    }
    const { classes, children } = this.props;
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    );
  }

  renderAppBar() {
    const { classes } = this.props;

    // With no sidebar no need to show the menu icon. Instead show the
    // TenTen logo for all sizes.
    if (this.props.hideSidebar) {
      return (
        <AppBar position="fixed" color="inherit" className={classes.appBar}>
          <Toolbar>
            <picture>
              <img src={logoUrl} alt="" height="38" />
            </picture>
          </Toolbar>
        </AppBar>
      );
    }

    return (
      <AppBar position="fixed" color="inherit" className={classes.appBar}>
        <Toolbar>
          <Hidden mdUp implementation="css">
            <IconButton
              edge="start"
              color="secondary"
              aria-label="menu"
              onClick={this.toggleSidebarActive}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden xsDown implementation="css">
            <div>
              <Link to="/">
                <picture>
                  <img src={logoUrl} alt="React" height="38" />
                </picture>
              </Link>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderAppBar()}
        {this.renderSidebar()}
        {this.renderContents()}
        <ErrorPopup />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { currentUser } = state;
  return {
    currentUser,
    history,
  };
};

const reduxConnection = connect(mapStateToProps);

const styled = withStyles(normalizeCss, s, flexboxgrid);

export default compose(
  reduxConnection,
  styled,
  injectIntl,
  MuiWithStyles(muiLayoutStyles),
)(Layout);
