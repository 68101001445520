import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'redux';
import gql from 'graphql-tag';
import { Field, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import TextField from '../../components/ReduxForm/TextField';
import { email, required } from '../../components/ReduxForm/Validators';
import { login, fetchCurrentUser } from '../../actions/login';
import history from '../../history';

export const dep = {
  history,
};

export const messages = defineMessages({
  email: {
    id: 'login.email',
    defaultMessage: 'Email address',
    description: 'Email for login credentials',
  },
  password: {
    id: 'login.password',
    defaultMessage: 'Password',
    description: 'Password for login credentials',
  },
  loginButton: {
    id: 'login.loginButton',
    defaultMessage: 'Login',
    description: 'Login button text',
  },
  loggingIn: {
    id: 'login.loggingIn',
    defaultMessage: 'Logging in',
    description: 'Indicate awaiting login response',
  },
  wrongCredentials: {
    id: 'login.wrongCredentials',
    defaultMessage: 'Wrong username or password',
    description: 'Wrong username or password',
  },
  otherError: {
    id: 'login.otherError',
    defaultMessage: 'Unknown error',
    description: 'Unspecified error',
  },
});

const LoginStatus = {
  LOADING_JS: 'loadingJs',
  AWAITING_LOGIN: 'awaitingLogin',
  LOGGING_IN: 'loggingIn',
  WRONG_CREDENTIALS: 'wrongCredentials',
  OTHER_ERROR: 'otherError',
};

class Login extends React.Component {
  static propTypes = {
    ...reduxFormPropTypes,
    mutate: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    fetchCurrentUser: PropTypes.func.isRequired,
  };

  static contextTypes = { fetch: PropTypes.func.isRequired };

  constructor(props) {
    super(props);

    this.state = {
      status: LoginStatus.LOADING_JS,
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.resetStatus = this.resetStatus.bind(this);
  }

  componentDidMount() {
    this.setState({ status: LoginStatus.AWAITING_LOGIN });
  }

  async handleLogin({ username, password }) {
    this.setState({ status: LoginStatus.LOGGING_IN });

    const statusCode = await this.props.login(username.trim(), password);

    if (statusCode === 201) {
      await this.props.fetchCurrentUser(this.props.mutate);
      history.push('/');
    } else if (statusCode === 401) {
      this.setState({ status: LoginStatus.WRONG_CREDENTIALS });
    } else {
      this.setState({ status: LoginStatus.OTHER_ERROR });
    }
  }

  resetStatus() {
    if (this.state.status !== LoginStatus.AWAITING_LOGIN) {
      this.setState({ status: LoginStatus.AWAITING_LOGIN });
    }
  }

  renderUsername = () => (
    <Field
      name="username"
      component={TextField}
      type="text"
      label={<FormattedMessage {...messages.email} />}
      icon={<EmailIcon />}
      onChange={this.resetStatus}
      validate={[required, email]}
      disabled={this.state.status === LoginStatus.LOADING_JS}
    />
  );

  renderPassword = () => (
    <Field
      name="password"
      component={TextField}
      type="password"
      label={<FormattedMessage {...messages.password} />}
      icon={<LockIcon />}
      onChange={this.resetStatus}
      validate={required}
      disabled={this.state.status === LoginStatus.LOADING_JS}
    />
  );

  renderSubmitButton = () => {
    const disabled =
      this.props.invalid ||
      [LoginStatus.LOGGING_IN, LoginStatus.LOADING_JS].includes(
        this.state.status,
      );

    return (
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={disabled}
        style={{ width: '100%' }}
      >
        <FormattedMessage {...messages.loginButton} />
      </Button>
    );
  };

  renderStatus = () => {
    switch (this.state.status) {
      case LoginStatus.LOGGING_IN:
        return <FormattedMessage {...messages.loggingIn} />;
      case LoginStatus.WRONG_CREDENTIALS:
        return <FormattedMessage {...messages.wrongCredentials} />;
      case LoginStatus.OTHER_ERROR:
        return <FormattedMessage {...messages.otherError} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.handleLogin)}>
        <div className="row center-xs" style={{ paddingTop: '5%' }}>
          <div className="col-md-3 col-sm-5 col-xs-12">
            <div className="row">
              <div className="col-xs-12">{this.renderUsername()}</div>
            </div>
            <div className="row">
              <div className="col-xs-12">{this.renderPassword()}</div>
            </div>
            <div className="row">
              <div className="col-xs-12" style={{ marginTop: '10px' }}>
                {this.renderSubmitButton()}
                <div style={{ marginTop: '10px' }}>{this.renderStatus()}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const loginForm = reduxForm({ form: 'LoginForm' });

const graphqlMutation = graphql(
  gql`
    mutation CurrentUser {
      currentUser {
        name
        email
        roleNames
        permissionNames
        operatorId
        operatorBranchId
      }
    }
  `,
);

const connectRedux = connect(null, { login, fetchCurrentUser });

export { Login };
export default compose(graphqlMutation, loginForm, connectRedux)(Login);
