import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import HelpIcon from '@material-ui/icons/Help';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import messages from './messages';

const styles = withStyles(() => ({
  listIcon: {
    minWidth: '30px',
  },
}));

const HelpMenu = ({ classes }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const ODS_GUIDE =
    'https://mytenten.zendesk.com/hc/ja/categories/5074708462489-%E8%87%AA%E8%B2%A9%E6%A9%9FAI%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB';
  const ODS_Q_AND_A =
    'https://mytenten.zendesk.com/hc/ja/categories/4414513381529-%E8%87%AA%E8%B2%A9%E6%A9%9FAI-Q-A';

  const handleClick = event => {
    if (!menuAnchor) {
      setMenuAnchor(event.currentTarget);
    }
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const handleOpenManual = () => {
    window.open(ODS_GUIDE);
    handleClose();
  };

  const handleOpenQAndA = () => {
    window.open(ODS_Q_AND_A);
    handleClose();
  };

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon className={classes.listIcon} size="small">
          <HelpIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage {...messages.help} />} />
      </ListItem>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        open={Boolean(menuAnchor)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenManual}>
          <MenuBookIcon fontSize="small" style={{ marginRight: '10px' }} />
          <FormattedMessage {...messages.guide} />
        </MenuItem>
        <MenuItem onClick={handleOpenQAndA}>
          <MenuBookIcon fontSize="small" style={{ marginRight: '10px' }} />
          <FormattedMessage {...messages.qAndA} />
        </MenuItem>
      </Menu>
    </div>
  );
};

HelpMenu.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default styles(HelpMenu);
