import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Provider as ReduxProvider } from 'react-redux';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../../assets/material-ui/theme';

// Dialogs are rendered outside the scope of the Providers in App.js
// The commponent adds back the providers which are missing.
export const ConnectedDialog = (props, context) => {
  const { insertCss, store } = context;
  return (
    <Dialog {...props}>
      <StyleContext.Provider value={{ insertCss }}>
        <ThemeProvider theme={theme}>
          <ReduxProvider store={store}>{props.children}</ReduxProvider>
        </ThemeProvider>
      </StyleContext.Provider>
    </Dialog>
  );
};

ConnectedDialog.propTypes = {
  children: PropTypes.node.isRequired, // eslint-disable-line react/forbid-prop-types
};

ConnectedDialog.contextTypes = {
  insertCss: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ConnectedDialog;
