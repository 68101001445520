import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import KitchenIcon from '@material-ui/icons/Kitchen';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import DirectionsIcon from '@material-ui/icons/Directions';
import RedeemIcon from '@material-ui/icons/Redeem';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withStyles } from '@material-ui/core/styles';
import intlShape from '../../constants/intlShape';
import Authorizer from '../../lib/Authorizer';
import SidebarFooter from './SidebarFooter';

const styles = withStyles(theme => ({
  drawerRoot: {
    width: '250px',
  },
  drawerPaperRoot: {
    backgroundColor: theme.palette.grey['50'],
    width: '250px',
  },
  sidebarRoot: {
    minWidth: '250px',
  },
  toolbar: theme.mixins.toolbar,
  listIcon: {
    minWidth: '30px',
  },
}));

const messages = defineMessages({
  events: {
    id: 'sidebar.events',
    defaultMessage: 'Latest events',
    description: 'Sidebar events link',
  },
  locations: {
    id: 'sidebar.locations',
    defaultMessage: 'Locations',
    description: 'Sidebar locations link',
  },
  routes: {
    id: 'sidebar.routes',
    defaultMessage: 'Routes',
    description: 'Sidebar routes link',
  },
  userManagement: {
    id: 'sidebar.userManagement',
    defaultMessage: 'User management',
    description: 'Sidebar user management link',
  },
  vendingMachines: {
    id: 'sidebar.vendingMachines',
    defaultMessage: 'Vending machines',
    description: 'Sidebar vending machines link',
  },
  imports: {
    id: 'sidebar.imports',
    defaultMessage: 'Imports',
    description: 'Sidebar imports link',
  },
  exports: {
    id: 'sidebar.exports',
    defaultMessage: 'Exports',
    description: 'Sidebar exports link',
  },
  locationOwners: {
    id: 'sidebar.locationOwners',
    defaultMessage: 'Location Owners',
    description: 'Sidebar location owner link',
  },
  operatorProducts: {
    id: 'sidebar.operatorProducts',
    defaultMessage: 'Products',
    description: 'Sidebar products link',
  },
  operator: {
    id: 'sidebar.operator',
    defaultMessage: 'Operator',
    description: 'Operator link',
  },
});

class Sidebar extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onSidebarLinkClicked: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    currentUser: PropTypes.shape({ name: PropTypes.string.isRequired })
      .isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    authorizer: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    currentPath: PropTypes.string.isRequired,
  };

  handleLinkClicked = (event, link) => {
    event.preventDefault();
    this.props.onSidebarLinkClicked();
    this.props.history.push(link);
  };

  routes() {
    const {
      intl: { formatMessage },
      currentPath,
    } = this.props;
    const links = [
      {
        label: formatMessage(messages.locations),
        icon: <LocationCityIcon fontSize="small" />,
        active: currentPath.startsWith('/locations') || currentPath === '/',
        onClick: event => this.handleLinkClicked(event, '/locations'),
        isVisible: true,
      },
      {
        label: formatMessage(messages.vendingMachines),
        icon: <KitchenIcon fontSize="small" />,
        active:
          currentPath.startsWith('/vending-machines') ||
          currentPath.startsWith('/vending-machine-models'),
        onClick: event => this.handleLinkClicked(event, '/vending-machines'),
        isVisible: true,
      },
      {
        label: formatMessage(messages.locationOwners),
        icon: <BusinessCenterIcon fontSize="small" />,
        active:
          currentPath.startsWith('/location-owners') ||
          currentPath.startsWith('/location-owner-fees'),
        onClick: event => this.handleLinkClicked(event, '/location-owners'),
        isVisible: true,
      },
      {
        label: formatMessage(messages.routes),
        icon: <DirectionsIcon fontSize="small" />,
        active: currentPath.startsWith('/routes'),
        onClick: event => this.handleLinkClicked(event, '/routes'),
        isVisible: this.props.authorizer.canShowRoutes(),
      },
      {
        label: formatMessage(messages.events),
        icon: <RedeemIcon fontSize="small" />,
        active: currentPath === '/events',
        onClick: event => this.handleLinkClicked(event, '/events'),
        isVisible: true,
      },
      {
        label: formatMessage(messages.operatorProducts),
        icon: <BatteryFullIcon fontSize="small" />,
        active:
          currentPath.startsWith('/operator-products') ||
          currentPath.startsWith('/product-variants') ||
          currentPath.startsWith('/unmapped-operator-products'),
        onClick: event => this.handleLinkClicked(event, '/operator-products'),
        isVisible: this.props.authorizer.canManageOperatorProducts(),
      },
      {
        label: formatMessage(messages.operator),
        icon: <BusinessIcon fontSize="small" />,
        active: currentPath.startsWith('/operator-branches'),
        onClick: event => this.handleLinkClicked(event, '/operator-branches'),
        isVisible: this.props.authorizer.canReadOpBranches(),
      },
      {
        label: formatMessage(messages.imports),
        icon: <CloudUploadIcon fontSize="small" />,
        active: currentPath === '/imports',
        onClick: event => this.handleLinkClicked(event, '/imports'),
        isVisible: this.props.authorizer.canCreateImports(),
      },
      {
        label: formatMessage(messages.exports),
        icon: <FileCopyIcon fontSize="small" />,
        active: currentPath === '/exports',
        onClick: event => this.handleLinkClicked(event, '/exports'),
        isVisible: this.props.authorizer.canCreateExports(),
      },
      {
        label: formatMessage(messages.userManagement),
        icon: <AccessibilityIcon fontSize="small" />,
        active: currentPath.startsWith('/userManagement'),
        onClick: event => this.handleLinkClicked(event, '/userManagement'),
        isVisible: this.props.authorizer.canManageUsers(),
      },
    ];

    return links.filter(link => link.isVisible).map(link => ({
      label: link.label,
      icon: link.icon,
      active: link.active,
      onClick: link.onClick,
    }));
  }

  renderSidebarContents() {
    const { classes } = this.props;
    return (
      <div className={classes.sidebarRoot}>
        <div className={classes.toolbar} />
        <List dense>
          {this.routes().map(route => (
            <ListItem
              button
              key={route.label}
              selected={route.active}
              onClick={route.onClick}
            >
              <ListItemIcon className={classes.listIcon} size="small">
                {route.icon}
              </ListItemIcon>
              <ListItemText primary={route.label} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <SidebarFooter
          onSidebarLinkClicked={this.props.onSidebarLinkClicked}
          onLinkClicked={this.handleLinkClicked}
          currentUser={this.props.currentUser}
          intl={this.props.intl}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <nav>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            className={classes.drawerRoot}
            PaperProps={{
              className: classes.drawerPaperRoot,
            }}
          >
            {this.renderSidebarContents()}
          </Drawer>
        </Hidden>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            open={this.props.active}
            onClose={this.props.onSidebarLinkClicked}
            PaperProps={{
              className: classes.drawerPaperRoot,
            }}
          >
            {this.renderSidebarContents()}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}

const connectRedux = connect(state => {
  const currentUser = state.currentUser || {};
  const authorizer = new Authorizer(currentUser.permissionNames);
  return {
    authorizer,
  };
});

const StyledSidebar = styles(Sidebar);
export { StyledSidebar as Sidebar };
export default compose(injectIntl, connectRedux)(StyledSidebar);
