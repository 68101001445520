import React from 'react';
import Layout from '../../components/Layout';
import Login from './Login';

function action() {
  return {
    chunks: ['login'],
    title: 'Login',
    component: (
      <Layout hideSidebar>
        <Login />
      </Layout>
    ),
  };
}

export default action;
