import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ConnectedDialog as Dialog } from '../ConnectedDialog';
import { clearError } from '../../actions/errors';
import commonMessages from '../../messages/commonMessages';

class ErrorPopup extends React.Component {
  static propTypes = {
    showError: PropTypes.bool.isRequired,
    title: PropTypes.node,
    message: PropTypes.node,
    exceptionMessage: PropTypes.node,
    clearError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    message: '',
    exceptionMessage: '',
  };

  handleClose = () => {
    this.props.clearError();
  };

  actions = () => [
    {
      label: <FormattedMessage {...commonMessages.close} />,
      onClick: this.handleClose,
    },
  ];

  render() {
    return (
      <Dialog open={this.props.showError} onClose={this.handleClose}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '20px' }}>{this.props.message}</div>
          <div style={{ fontSize: '10px' }}>{this.props.exceptionMessage}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>
            <FormattedMessage {...commonMessages.close} />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const { errors } = state;
  const { showError, title, message, exceptionMessage } = errors;

  return {
    showError,
    title,
    message,
    exceptionMessage,
  };
};

const connectedToRedux = connect(mapStateToProps, {
  clearError,
});

export { ErrorPopup };

export default connectedToRedux(ErrorPopup);
