import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageIcon from '@material-ui/icons/Language';
import { setLocale as intlSetLocale } from '../../actions/intl';

const styles = withStyles(() => ({
  listIcon: {
    minWidth: '30px',
  },
}));

class LanguageSwitcher extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    currentLocale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    onSelect: () => {},
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      menuAnchor: null,
    };
  }

  handleClick(event) {
    const { menuAnchor } = this.state;
    if (!menuAnchor) {
      this.setState({ menuAnchor: event.currentTarget });
    }
  }

  handleClose() {
    this.setState({ menuAnchor: null });
  }

  handleLanguageSelected = locale => {
    if (locale === this.props.currentLocale) {
      return;
    }

    this.props.setLocale({ locale });
    this.props.onSelect({ locale });
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    const { menuAnchor } = this.state;
    let selectedLabel;
    let unselectedLabel;
    let inactiveLocale;
    if (this.props.currentLocale === 'en-US') {
      selectedLabel = 'English';
      unselectedLabel = '日本語';
      inactiveLocale = 'ja-JP';
    } else {
      selectedLabel = '日本語';
      unselectedLabel = 'English';
      inactiveLocale = 'en-US';
    }

    return (
      <div>
        <ListItem button onClick={this.handleClick}>
          <ListItemIcon className={classes.listIcon} size="small">
            <LanguageIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={selectedLabel} />
        </ListItem>
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          open={Boolean(menuAnchor)}
          onClose={this.handleClose}
          disablePortal
        >
          <MenuItem onClick={() => this.handleLanguageSelected(inactiveLocale)}>
            <LanguageIcon fontSize="small" style={{ marginRight: '10px' }} />
            {unselectedLabel}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapState = state => ({
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  setLocale: intlSetLocale,
};

export default compose(connect(mapState, mapDispatch), styles)(
  LanguageSwitcher,
);
