import { defineMessages } from 'react-intl';

const messages = defineMessages({
  logout: {
    id: 'layout.logout',
    defaultMessage: 'Logout',
    description: 'Logout button text in header',
  },
  help: {
    id: 'layout.help',
    defaultMessage: 'Help',
    description: 'Help button',
  },
  guide: {
    id: 'layout.guide',
    defaultMessage: 'Manual',
    description: 'Guide button',
  },
  qAndA: {
    id: 'layout.qAndA',
    defaultMessage: 'Q&A',
    description: 'Q&A button',
  },
});

export default messages;
