import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import { logout } from '../../actions/login';
import LanguageSwitcher from '../LanguageSwitcher';
// eslint-disable-next-line css-modules/no-unused-class
import HelpMenu from './HelpMenu';
import messages from './messages';

const styles = withStyles(() => ({
  listIcon: {
    minWidth: '30px',
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

class SidebarFooter extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onSidebarLinkClicked: PropTypes.func.isRequired,
    onLinkClicked: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({ name: PropTypes.string.isRequired })
      .isRequired,
    logout: PropTypes.func.isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.footer}>
        <List dense>
          <ListItem
            button
            onClick={event => this.props.onLinkClicked(event, '/manager')}
          >
            <ListItemIcon className={classes.listIcon} size="small">
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.truncate }}
              primary={
                (this.props.currentUser && this.props.currentUser.name) ||
                'Profile'
              }
            />
          </ListItem>
          <LanguageSwitcher onSelect={this.props.onSidebarLinkClicked} />
          <HelpMenu />
          <ListItem button onClick={this.props.logout}>
            <ListItemIcon className={classes.listIcon} size="small">
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage {...messages.logout} />} />
          </ListItem>
        </List>
      </div>
    );
  }
}

const reduxConnection = connect(undefined, { logout });

export default styles(reduxConnection(SidebarFooter));
